.beian {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: 8px;
  margin-block-end: 8px;
  color: #fff;
  text-decoration: none;
  img {
    width: 22px;
    margin-right: 5px;
  }
}
