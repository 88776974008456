.wrapper {
  height: 20vw;
  overflow: hidden;
  .power {
    position: relative;
    transform: translateY(0);
    transition: transform 0.3s;
    height: 18vw;
    padding: 1vw 5vw;
    .btnGroup {
      float: right;
      margin-top: 1vw;
    }
    .self {
      font-size: small;
    }
  }
}
.container {
  transform: translateY(0);
  transition: transform 0.3s !important;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 20vw;
  padding: 0 5vw;
  .textWrapper {
    margin-left: 5vw;
    .name {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin-block-end: 0;
    }
    .subTitle {
      font-size: smaller;
    }
  }
}
