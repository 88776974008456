.wrapper {
  background-color: #fff;
  overflow: hidden;
  padding: 2vw 8vw;
  textarea {
    font-size: 14px !important;
  }
  .emoji {
    height: 0;
    opacity: 0.1;
    transition: all 0.2s;
    overflow: hidden;
    display: block;
    width: 100%;
  }
  .emojiShow {
    height: 88vw;
    opacity: 1;
  }
}
