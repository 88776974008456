.auto {
  height: 100%;
}
.skeletonText {
  width: 40vw;
}
.footer {
  height: 30px;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  opacity: 0.8;
}
