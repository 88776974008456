.links {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: flex-start;
  .a {
    text-decoration: none;
    font-size: smaller;
    color: rgb(57, 176, 240);
    margin-bottom: 5px;
  }
}
.img {
  width: 100%;
  max-width: 100%;
  display: block;
}
