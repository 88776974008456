.footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #fff;

  img {
    width: 1rem;
    max-width: 100%;
    margin-right: 2px;
  }
}
.wrapper {
  height: 100%;
  overflow: hidden;
  .yq {
    height: 25vw;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .list {
    max-height: 100%;
    height: calc(100% - 25vw - 60px - 50px);
    overflow-y: auto;
    padding-top: 0;
    padding-bottom: 50px;
    box-sizing: border-box;
  }
  .description {
    font-size: smaller;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    .descText {
      width: auto;
      position: relative;
      height: 20px;
      margin: 0 30px;
    }
  }
  .slider {
    height: 20px;
    width: 100% !important;
    max-width: 100% !important;
  }
}
