@keyframes move {
  from {
    transform: translateX(90vw);
  }
  to {
    transform: translateX(-95vw);
  }
}
.chipAnim {
  animation: move 3s linear infinite;
  transform: translateX(95vw);
}
