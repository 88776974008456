.wrapper {
  display: block;
  height: 100%;
  position: fixed;
  z-index: 99;
  padding: 1vw 3vw;
  font-size: 12px;
  top: 48px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  textarea {
    font-size: 14px !important;
    letter-spacing: 0.7px;
  }
}
.none {
  display: none !important;
}
.preview {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.emoji {
  height: 0;
  opacity: 0.1;
  transition: all 0.2s;
  overflow: hidden;
  display: block;
  width: 100%;
}
.emojiShow {
  height: 88vw;
  opacity: 1;
}
.appBar {
  flex-flow: row nowrap !important;
  align-items: center;
  justify-content: flex-start;
}
.title {
  display: inline-block;
  width: 60%;
  text-align: center;
  font-size: smaller;
}
