.wrapper {
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  background: #fff;
  align-items: center;
  overflow: hidden;
  .ul {
    text-decoration: none;
    text-align: left;
    padding-inline-start: 0;
    align-self: flex-start;
    transition: transform 1s;
    width: 85%;
    li {
      text-decoration: none;
      list-style: none;
      padding: 0;
      margin: 0;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      font-size: smaller;
    }
  }
}
