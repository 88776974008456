.wrap {
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img[alt='demo'] {
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 50;
    filter: blur(113px);
    transform: scale(0.95);
    transition: all 0.42s linear;
  }
  .gif {
    height: 100%;
    position: absolute;
    object-fit: cover;
    transform: scale(1.52);
    filter: blur(1px);
    transition: all 0.23s linear;
  }
  .img-anim {
    filter: blur(0) !important;
    transform: scale(1) !important;
  }
  .bg-anim {
    transform: scale(1.1);
    filter: blur(12px);
  }
}
