.paragraph {
  font-size: 0.75rem !important;
  margin-bottom: 0 !important;
  word-break: break-all;
  -webkit-tap-highlight-color: rgba(119, 203, 241, 0.5);
  cursor: pointer;
  &:active {
    background-color: rgba(119, 203, 241, 0.5);
  }
}

.comment {
  padding: 0 5vw;
  margin-bottom: 1rem;
}
.img {
  max-width: 100%;
  width: 100%;
  margin-top: 6px;
}
.contentText {
  color: #291488 !important;
  display: inline;
  font-size: 0.75rem !important;
  padding-left: 5px;
  font-weight: 500;
}
.FavoriteIcon {
  transition: color 0.3s;
}
.nameNormal {
  font-weight: 600;
}
.selfActive {
  font-weight: 600;
  color: rgb(209, 18, 18);
}
.titleWrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  .sex {
    width: 18px;
    height: 18px;
    max-width: 100%;
    margin-left: 8px;
    transform: translateY(-1px);
  }
  .title {
    font-weight: 600;
  }
}
